<template>
  <base-wrapper :loading="loading">
    <datatable
      :columns="tableColumns"
      :total="stockBatches.meta.page.total"
      :perPage="stockBatches.meta.page.perPage"
      :currentPage="stockBatches.meta.page.currentPage"
      @pagechanged="onChangePage"
    >
      <template #tbody="{ classes }">
        <tr
          v-for="stockBatch in stockBatches.data"
          :key="stockBatch.id"
          :class="[classes.tr, 'bg-white']"
        >
          <td :class="classes.td">
            <p class="font-bold text-gray-900">
              {{ stockBatch.attributes.code }}

              <span v-if="stockBatch.attributes.product_type === 'free'">(PROMO)</span>
            </p>
          </td>
          <td :class="classes.td">{{ stockBatch.attributes.pickup_method_label }}</td>
          <td :class="classes.td">{{ stockBatch.attributes.product_code }}</td>
          <td :class="classes.td">{{ stockBatch.attributes.product_name }}</td>
          <td :class="classes.td">{{ stockBatch.attributes.stock_qty - stockBatch.attributes.stock_used_qty }}</td>
          <td :class="classes.td">{{ stockBatch.attributes.expire_at | formatDate }}</td>
          <td :class="[classes.td, 'text-center']">
            <toggle-button
              :labels="{ checked: 'Aktif', unchecked: 'Nonaktif' }"
              :width="stockBatch.attributes.is_active ? 60 : 75"
              v-model="stockBatch.attributes.is_active"
              @change="onChangeActive(stockBatch)"
            />
          </td>
        </tr>
      </template>
    </datatable>
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  mixins: [requestMixin],
  components: { ToggleButton },
  data() {
    return {
      loading: false,
      stockBatches: {
        data: [],
        meta: {
          page: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'code', name: 'No. Batch' },
        { id: 'type', name: 'Tipe' },
        { id: 'product_code', name: 'Kode Barang' },
        { id: 'product_name', name: 'Nama Barang' },
        { id: 'product_qty', name: 'Jumlah' },
        { id: 'expire_at', name: 'Kadaluarsa' },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    async loadStockBatches(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/warehouses/${this.me.current_warehouse}/stock-batches`,
        {
          params: {
            'fields[stock-batches]':
              'code,product_type,product_code,product_name,stock_qty,is_active,stock_used_qty,expire_at,pickup_method_label',
            'page[size]': 5,
            ...params,
          },
        }
      );

      if (!err) {
        this.stockBatches = res;
      }

      this.loading = false;
    },
    onChangePage(page) {
      this.loadStockBatches({
        'page[number]': page,
      });
    },
    async onChangeActive(stockBatch) {
      this.loading = true;

      await this.request(`/api/v1/stock-batches/${stockBatch.id}`, {
        method: 'patch',
        data: {
          data: {
            type: 'stock-batches',
            id: stockBatch.id,
            attributes: {
              is_active: stockBatch.attributes.is_active,
            },
          },
        },
      });

      this.loadStockBatches();

      this.loading = false;
    },
  },
  created() {
    this.loadStockBatches();
  },
};
</script>
